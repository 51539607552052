import { useContext } from 'react';
import SubmitAttempt from '../utils/utility/context';
import { Regex_Pattern } from '../utils/types/models';
import { BrandInformationObject } from '../utils/types/models';
import { InputText } from 'primereact/inputtext';
import { MaxLengthMapper } from '../utils/types/enums';
import { Sanitize } from '../utils/utility/sanitize';
import { useForm } from 'react-hook-form';

export default function BrandInformation({
  currentUser,
  brandInformationObject,
}) {
  const { submitAttempted } = useContext(SubmitAttempt);
  const {
    register,
    getValues,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      brandName: currentUser.brandInformation?.name || currentUser?.linkBrandName || brandInformationObject?.current?.name || null,
      website: currentUser?.brandInformation?.website || brandInformationObject?.current?.website || null,
      affiliatePlatformUrl: currentUser?.brandInformation?.affiliatePlatformUrl || brandInformationObject?.current?.affiliatePlatformUrl || null,
    },
  });

  brandInformationObject.current = BrandInformationObject(
    watch('brandName'),
    watch('website'),
    watch('affiliatePlatformUrl')
  );

  return (
    <div className="p-d-flex p-col p-fluid p-ai-center p-pl-3 p-pr-3">
      <div className="p-grid p-col-12">
        <div className="p-field p-col-5">
          <label>Brand Name *</label>
          <InputText
            type="text"
            className={(submitAttempted && !getValues('brandName')) || errors?.brandName?.message
              ? 'p-invalid p-inputtext-lg p-d-block input-w-validation'
              : ' p-inputtext-lg p-d-block '
            }
            {...register('brandName', {
              required: 'This field is required',
              onChange: (event) => {
                setValue('brandName', Sanitize.SanitizeBrandName(event.target.value));
                if (!Regex_Pattern.brand_name.test(event.target.value)) {
                  setError('brandName', { message: 'Only these special characters @ ! - _ . are allowed within and at the end.' });
                } else if (event.target.value.length > MaxLengthMapper.brandNameMaxLength) {
                  setError('You have exceeded the characters limit.');
                } else {
                  setError('brandName', { message: '' });
                }
              },
              onBlur: (event) => {
                const name = event.target.value.trim();
                setValue('brandName', name);
              }
            })}

          />
          <small className="p-error p-d-block validation-error">
            {(submitAttempted && !getValues('brandName')) ? "This field is required" : errors?.brandName?.message}
          </small>
        </div>
        <div className="p-field p-col-5">
          <label>Brand Website URL *</label>
          <InputText
            type="text"
            className={errors?.website?.message || (submitAttempted && !getValues('website'))
              ? 'p-invalid input-w-validation p-inputtext-lg p-d-block'
              : 'p-inputtext-lg p-d-block'
            }
            {...register('website', {
              onChange: (event) => {
                setValue('website', Sanitize.sanitizeWebsite(event.target.value));
                if (event.target.value.length > MaxLengthMapper.websiteMaxLength) {
                  setError('website', { message: 'You have exceeded the character limit.' });
                } else if (Sanitize.checkUppercase(event.target.value)) {
                  setValue('website', event.target.value.toLowerCase());
                } else if (!Regex_Pattern.websiteUrl.test(event.target.value)) {
                  setError('website', { message: "Invalid domain! The URL must start with 'http://' or 'https://' and end with a TLD '.com/.xyz', optionally include 'www.'." });
                } else {
                  setError('website', { message: '' });
                }
              },
              onBlur: (event) => {
                if (!Regex_Pattern.websiteUrl.test(event.target.value)) {
                  setError('website', { message: "Invalid domain! The URL must start with 'http://' or 'https://' and end with a TLD '.com/.xyz', optionally include 'www.'." });
                } else if (Sanitize.checkUppercase(event.target.value)) {
                  setError('website', { message: 'No capital letters are allowed.' });
                } else {
                  setError('website', { message: '' });
                }
              }
            })}
          />
          <small className="p-error p-d-block validation-error">
            {(submitAttempted && !getValues('website')) ? "Brand Website URL is required" : errors?.website?.message}
          </small>
        </div>
        <div className="p-field p-col-5">
          <label>Affiliate Platform URL *</label>
          <InputText
            type="text"
            className={
              errors?.affiliatePlatformUrl?.message || (submitAttempted && !getValues('affiliatePlatformUrl'))
                ? 'p-invalid input-w-validation p-inputtext-lg p-d-block'
                : 'p-inputtext-lg p-d-block'
            }
            {...register('affiliatePlatformUrl', {
              onChange: (event) => {
                setValue('affiliatePlatformUrl', event.target.value);
                if (!Regex_Pattern.websiteUrl.test(event.target.value)) {
                  setError('affiliatePlatformUrl', { message: "Invalid domain! The URL must start with 'http://' or 'https://' and end with a TLD '.com/.xyz', optionally include 'www.'." });
                } else {
                  setError('affiliatePlatformUrl', { message: '' });
                }
              },
              onBlur: (event) => {
                if (!Regex_Pattern.websiteUrl.test(event.target.value)) {
                  setError('affiliatePlatformUrl', { message: "Invalid domain! The URL must start with 'http://' or 'https://' and end with a TLD '.com/.xyz', optionally include 'www.'." });
                } else {
                  setError('affiliatePlatformUrl', { message: '' });
                }
              }
            })}
          />
          <small className="p-error p-d-block validation-error">
            {(submitAttempted && !getValues('affiliatePlatformUrl')) ? "Affiliate Platform URL is required" : errors?.affiliatePlatformUrl?.message}
          </small>
        </div>
      </div>
    </div>
  );
}
