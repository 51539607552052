import { useEffect, useContext, useState } from "react";
import SubmitAttempt from "../utils/utility/context";
import { BankingDetailsObject, Regex_Pattern } from "../utils/types/models";
import { formatDisplayOption, isValidDurationFormat, formatDurationString } from "../utils/utility/functions";
import { createSelectItems, getCurrencies } from '../utils/utility/formHelpers'
import CurrencyInput from "../components/CurrencyInput";
import MultiSelectModalPopUp from "../components/Multiselect-modal-popup";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";

export default function BankingDetails({
  allCurrencies,
  paymentMethods,
  bankingDetailsObject,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { submitAttempted } = useContext(SubmitAttempt);
  const [openModal, setOpenModal] = useState(false);
  const [typeOfObjects, setTypeOfObjects] = useState("");
  const {
    currencies = null,
    bankTransfersNumberOfDays,
    bitcoinNumberOfDays,
    chequeNumberOfDays,
    creditCardsNumberOfDays,
    currencyDetails,
    documentValidationNumberOfDays,
    eWalletNumberOfDays,
    paymentMethodsDeposit,
    paymentMethodsWithdrawal
  } = bankingDetailsObject?.current || {};
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currencies: currencies || '',
      bankTransfersNumberOfDays: bankTransfersNumberOfDays || '',
      bitcoinNumberOfDays: bitcoinNumberOfDays || '',
      chequeNumberOfDays: chequeNumberOfDays || '',
      creditCardsNumberOfDays: creditCardsNumberOfDays || '',
      currencyDetails: currencyDetails || [],
      documentValidationNumberOfDays: documentValidationNumberOfDays || '',
      eWalletNumberOfDays: eWalletNumberOfDays || '',
      paymentMethodsDeposit: paymentMethodsDeposit || '',
      paymentMethodsWithdrawal: paymentMethodsWithdrawal || '',
    },
  });

  function openFromParent(type) {
    setTypeOfObjects(type);
    setOpenModal(true);
  }

  paymentMethods = paymentMethods.firestoreData.filter(
    (paymentMethod) => paymentMethod.enabled === true
  );
  allCurrencies.firestoreData.forEach((currency) => {
    currency.code = currency.code.toUpperCase();
  });

  const paymentMethodsWithdrawalSelectItem = createSelectItems(paymentMethods);
  const paymentMethodsDepositSelectItem = createSelectItems(paymentMethods);
  const inputPopUpPaymentsDeposit = {
    paymentMethodsDepositSelectItem,
    paymentMethodsDeposit: getValues("paymentMethodsDeposit"),
  };
  const inputPopUpPaymentsWithdraw = {
    paymentMethodsWithdrawalSelectItem,
    paymentMethodsWithdrawal: getValues("paymentMethodsWithdrawal"),
  };

  bankingDetailsObject.current = BankingDetailsObject(
    watch("paymentMethodsDeposit"),
    watch("paymentMethodsWithdrawal"),
    watch("creditCardsNumberOfDays"),
    watch("eWalletNumberOfDays"),
    watch("bankTransfersNumberOfDays"),
    watch("chequeNumberOfDays"),
    watch("bitcoinNumberOfDays"),
    watch("documentValidationNumberOfDays"),
    watch("currencies"),
    watch("currencyDetails")
  );

  /**
   * This function validates duration fields, if valid it formats appropriately and set the given field with the formatted value.
   * @param {*} event 
   * @param {string} field 
   */
  const formatAndSetDurationField = (event, field) => {

    let { value } = event.target;
    let formattedValue = value.trim();

    //check and add a space before the unit
    if (!/\d\s((h|d|w|m|min).*)/.test(formattedValue)) {
      formattedValue = formattedValue.replace(/(\d+)((h|d|w|m|min).*)/g, "$1 $2");
    }

    //validate and format
    if (isValidDurationFormat(formattedValue)) {
      formattedValue = formatDurationString(formattedValue).toLowerCase().replace(Regex_Pattern.remove_extra_spaces, ' ').trim();
      errors[field] = undefined;

      //set formatted field value
      setValue(field, formattedValue);
    } else {
      errors[field] = value==="" ? undefined : "Please use the correct format";
      setValue(field, value);
      return;
    }
  }

  return (
    <>
      <div className="p-d-flex p-mt-3">
        {openModal && (
          <MultiSelectModalPopUp
            IsModalOpened={openModal}
            setValue={setValue}
            onCloseModal={() => setOpenModal(false)}
            inputTypeOfObjects={typeOfObjects}
            inputPopUpPaymentsDeposit={inputPopUpPaymentsDeposit}
            inputPopUpPaymentsWithdraw={inputPopUpPaymentsWithdraw}
          />
        )}
        <div className="p-d-flex ">
          <div className="p-fluid p-col-6 p-pl-4 p-pr-4 ">
            <Card
              title="Select your currencies *"
              className={
                submitAttempted &&
                  (!getValues("currencyDetails") ||
                    getValues("currencyDetails")?.length === 0)
                  ? "p-mb-3 p-invalid"
                  : "p-mb-3"
              }
            >
              <div className="p-d-flex p-grid">
                {allCurrencies.firestoreData.map((currency, index) => {
                  return (
                    <div className="p-field-checkbox p-col-3" key={index}>
                      <Checkbox
                        className="p-mr-2"
                        onChange={() => {
                          const currenciesDetails = getValues("currencyDetails");
                          let newCurrency = [...currenciesDetails];
                          let currentCurrency = currenciesDetails.some(
                            (element) => element.currency === currency.code
                          );

                          if (!currentCurrency) {
                            newCurrency.push({
                              currency: currency.code,
                              minimumDeposit: "",
                              cashoutLimit: "",
                            });
                            setValue("currencyDetails", newCurrency);
                            setValue("currencies", getCurrencies(newCurrency));
                          } else {
                            let currencyToDelete = currenciesDetails.findIndex(
                              (element) => element.currency === currency.code
                            );
                            newCurrency.splice(currencyToDelete, 1);
                            setValue("currencyDetails", newCurrency);
                            setValue("currencies", getCurrencies(newCurrency));
                          }
                        }}
                        checked={getValues("currencyDetails")?.some(
                          (element) => element.currency === currency.code
                        )}
                      ></Checkbox>
                      <label>{currency.code}</label>
                    </div>
                  );
                })}
              </div>
            </Card>

            <div className="p-col-12 p-grid p-m-0 p-p-0">
              {getValues("currencyDetails")?.map((currency, index) => {
                return (
                  <CurrencyInput
                    currency={currency}
                    key={index}
                    index={index}
                    currenciesDetails={getValues("currencyDetails")}
                    setValue={setValue}
                  />
                );
              })}
            </div>
            <small className='p-error p-d-block validation-error'>
              {(submitAttempted && getValues('currencyDetails').length === 0) ? "Currency Details is required" : errors?.currencyDetails?.message}
            </small>
          </div>
          <div className="p-col-6 p-fluid p-grid p-pl-4 p-pr-4 p-as-start">
            <div className="p-field p-col-12">
              <label>
                Payment Methods Deposit *
                {getValues("paymentMethodsDeposit")?.length > 0 && (
                  <Button
                    icon="pi pi-copy"
                    className="p-button-rounded p-button-warning p-ml-2"
                    aria-label="CopyToWithdrawal"
                    tooltip="Clone payments from deposit to withdrawal"
                    onClick={() =>
                      setValue(
                        "paymentMethodsWithdrawal",
                        getValues("paymentMethodsDeposit")
                      )
                    }
                  />
                )}
              </label>
              <MultiSelect
                selectedItemTemplate={
                  getValues("paymentMethodsDeposit") &&
                    getValues("paymentMethodsDeposit")?.length > 0
                    ? formatDisplayOption(
                      paymentMethods,
                      getValues("paymentMethodsDeposit"),
                      "code"
                    )
                    : null
                }
                className={
                  submitAttempted &&
                    (!getValues("paymentMethodsDeposit") ||
                      getValues("paymentMethodsDeposit")?.length === 0)
                    ? "p-invalid"
                    : ""
                }
                value={getValues("paymentMethodsDeposit")}
                options={paymentMethodsDepositSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent("paymentMethodsDeposit")}
              />
              <small className='p-error p-d-block validation-error'>
                {(submitAttempted && getValues('paymentMethodsDeposit').length === 0) ? "Payment Methods Deposit is required" : errors?.paymentMethodsDeposit?.message}
              </small>
            </div>
            <div className="p-field p-col-12">
              <label>Payment Methods Withdrawal *</label>
              <MultiSelect
                selectedItemTemplate={
                  getValues("paymentMethodsWithdrawal") &&
                    getValues("paymentMethodsWithdrawal")?.length > 0
                    ? formatDisplayOption(
                      paymentMethods,
                      getValues("paymentMethodsWithdrawal"),
                      "code"
                    )
                    : null
                }
                className={
                  submitAttempted &&
                    (!getValues("paymentMethodsWithdrawal") ||
                      getValues("paymentMethodsWithdrawal")?.length === 0)
                    ? "p-invalid"
                    : ""
                }
                value={getValues("paymentMethodsWithdrawal")}
                options={paymentMethodsWithdrawalSelectItem}
                optionLabel="label"
                onFocus={() => openFromParent("paymentMethodsWithdrawal")}
              />
              <small className='p-error p-d-block validation-error'>
                {(submitAttempted && getValues('paymentMethodsWithdrawal').length === 0) ? "Payment Methods Withdrawal is required" : errors?.paymentMethodsWithdrawal?.message}
              </small>
            </div>
            <div className="p-field p-col-6">
              <label>Credit Card (No of days)</label>
              <InputText
                aria-invalid={errors.creditCardsNumberOfDays ? "false" : "true"}
                placeholder="ex: 1-5 d"
                value={getValues('creditCardsNumberOfDays')}
                onBlur={(e) => formatAndSetDurationField(e, "creditCardsNumberOfDays")}
                onChange={(e) => setValue('creditCardsNumberOfDays', e.target.value)}
              />
              {errors.creditCardsNumberOfDays && <small className="p-error">{errors.creditCardsNumberOfDays}</small>}
            </div>
            <div className="p-field p-col-6">
              <label>E-Wallets (No of days)</label>
              <InputText
                placeholder="ex: 1-5 d"
                value={getValues('eWalletNumberOfDays')}
                onBlur={(e) => formatAndSetDurationField(e, "eWalletNumberOfDays")}
                onChange={(e) => setValue('eWalletNumberOfDays', e.target.value)}
              />
              {errors.eWalletNumberOfDays && <small className="p-error">{errors.eWalletNumberOfDays}</small>}
            </div>

            <div className="p-field p-col-6">
              <label>Cheques (No of days)</label>
              <InputText
                placeholder="ex: 1-5 d"
                className="p-inputtext-lg p-d-block"

                value={getValues('chequeNumberOfDays')}
                {...register('chequeNumberOfDays', {
                  onChange: (e) => setValue('chequeNumberOfDays', e.target.value),
                  onBlur: (e) => formatAndSetDurationField(e, "chequeNumberOfDays")
                })}
              />
              {errors.chequeNumberOfDays && <small className="p-error">{errors.chequeNumberOfDays}</small>}
            </div>
            <div className="p-field p-col-6">
              <label>Bitcoins (No of days)</label>
              <InputText
                placeholder="ex: 1-5 d"
                className="p-inputtext-lg p-d-block"
                value={getValues('bitcoinNumberOfDays')}
                onBlur={(e) => formatAndSetDurationField(e, "bitcoinNumberOfDays")}
                onChange={(e) => setValue('bitcoinNumberOfDays', e.target.value)}
              />
              {errors.bitcoinNumberOfDays && <small className="p-error">{errors.bitcoinNumberOfDays}</small>}
            </div>
            <div className="p-field p-col-12">
              <label>Bank Transfers (No of days)</label>
              <InputText
                placeholder="ex: 1-5 d"
                value={getValues('bankTransfersNumberOfDays')}
                onBlur={(e) => formatAndSetDurationField(e, "bankTransfersNumberOfDays")}
                onChange={(e) => setValue('bankTransfersNumberOfDays', e.target.value)}
              />
              {errors.bankTransfersNumberOfDays && <small className="p-error">{errors.bankTransfersNumberOfDays}</small>}
            </div>
            <div className="p-field p-col-12">
              <label>Document Validation (No of days)</label>
              <InputText
                placeholder="ex: 1-5 d"
                value={getValues('documentValidationNumberOfDays')}
                onBlur={(e) => formatAndSetDurationField(e, "documentValidationNumberOfDays")}
                onChange={(e) => setValue('documentValidationNumberOfDays', e.target.value)}
              />
              {errors.documentValidationNumberOfDays && <small className="p-error">{errors.documentValidationNumberOfDays}</small>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
